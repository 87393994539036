import Graphic from '@arcgis/core/Graphic'
import FeatureLayer from '@arcgis/core/layers/FeatureLayer'
import UniqueValueRenderer from '@arcgis/core/renderers/UniqueValueRenderer'
import SimpleMarkerSymbol from '@arcgis/core/symbols/SimpleMarkerSymbol'
import { PostMaterialEnum, VineStateIdEnum } from '@typings/dtos/block-report/enums'
import { MAP_CLR } from '@utils/constants'
import { VISUAL_VARIABLES_LG, VISUAL_VARIABLES_MD, VISUAL_VARIABLES_SM } from './constants'
import { VineAndPostLayer } from './layers/pruning-vines.layer'
import { RowsLayer } from './layers/rows.layer'

export const applyEditsToFeatureLayer = (featureLayer: FeatureLayer, edits: __esri.FeatureLayerBaseApplyEditsEdits) => {
  featureLayer
    .applyEdits(edits)
    .then((results) => {
      // if edits were removed
      // if (results.deleteFeatureResults.length > 0) {
      //   console.log(results.deleteFeatureResults.length, 'features have been removed')
      // }
      // if features were added - call queryFeatures to return
      //    newly added graphics
      if (results.addFeatureResults.length > 0) {
        const objectIds: any = []
        results.addFeatureResults.forEach((item: any) => {
          objectIds.push(item.objectId)
        })
        // query the newly added features from the layer
        featureLayer.queryFeatures({
          objectIds: objectIds,
        })
        // .then((results: __esri.FeatureSet) => {
        //   console.log(results.features.length, 'features have been added.')
        // })
      }
    })
    .catch((error) => console.log(error))
}

export const setGraphicsToFeatureLayer = async (featureLayer: FeatureLayer, graphics: Graphic[]) => {
  const results = await featureLayer.queryFeatures()
  const addEdits = {
    deleteFeatures: results.features,
    addFeatures: graphics,
  }
  applyEditsToFeatureLayer(featureLayer, addEdits)
}

export const getMultipleGraphicsByIdFromFeatureLayer = async (featureLayer: FeatureLayer, ids: number[]) => {
  let graphics = [] as Graphic[]
  const results: __esri.FeatureSet = await featureLayer.queryFeatures()
  if (results.features.length) {
    graphics = results.features.filter((p) => ids.includes(p.attributes.id))
  }
  return graphics
}

export const removeGraphicsFromFeatureLayer = async (featureLayer: FeatureLayer[]) => {
  return featureLayer.forEach((layer) => {
    layer.queryFeatures().then((results) => {
      const deleteEdits = { deleteFeatures: results.features }
      applyEditsToFeatureLayer(layer, deleteEdits)
    })
  })
}

export const updateRowAndVineFeatureLayers = async (vineAndPostGraphics: Graphic[], rowGraphics: Graphic[]) => {
  const { features } = await VineAndPostLayer.queryFeatures()
  const { features: rowFeatures } = await RowsLayer.queryFeatures()

  const addVineEdits = { addFeatures: vineAndPostGraphics, deleteFeatures: features } as __esri.FeatureLayerBaseApplyEditsEdits
  const addRowEdits = { addFeatures: rowGraphics, deleteFeatures: rowFeatures } as __esri.FeatureLayerBaseApplyEditsEdits

  applyEditsToFeatureLayer(RowsLayer, addRowEdits)
  applyEditsToFeatureLayer(VineAndPostLayer, addVineEdits)
}

export const vinesLayerRenderer = (size?: 'sm' | 'md' | 'lg') => {
  const _size = (localStorage.getItem('map_marker_size') as 'sm' | 'md' | 'lg') || 'md'

  const visualVariables = {
    sm: VISUAL_VARIABLES_SM,
    md: VISUAL_VARIABLES_MD,
    lg: VISUAL_VARIABLES_LG,
  }

  return new UniqueValueRenderer({
    field: 'renderType',
    field2: 'haveImages',
    fieldDelimiter: ', ',
    uniqueValueInfos: [
      {
        value: 'purple, false',
        symbol: new SimpleMarkerSymbol({ color: '#A855F7' }),
      },
      {
        value: 'purple, true',
        symbol: new SimpleMarkerSymbol({ color: '#A855F7', outline: { color: '#FFF', width: 1 } }),
      },
      {
        value: 'orange, false',
        symbol: new SimpleMarkerSymbol({ color: '#FFA500' }),
      },
      {
        value: 'orange, true',
        symbol: new SimpleMarkerSymbol({ color: '#FFA500', outline: { color: '#FFF', width: 1 } }),
      },
      {
        value: 'red, false',
        symbol: new SimpleMarkerSymbol({ color: '#FF0000' }),
      },
      {
        value: 'red, true',
        symbol: new SimpleMarkerSymbol({ color: '#FF0000', outline: { color: '#FFF', width: 1 } }),
      },
      {
        value: 'black, false',
        symbol: new SimpleMarkerSymbol({ color: '#000000' }),
      },
      {
        value: 'black, true',
        symbol: new SimpleMarkerSymbol({ color: '#000000', outline: { color: '#FFF', width: 1 } }),
      },
    ],
    visualVariables: visualVariables[size || _size],
  })
}

export const vinesAndPostsRenderer = (size?: 'sm' | 'md' | 'lg') => {
  const _size = (localStorage.getItem('map_marker_size') as 'sm' | 'md' | 'lg') || 'md'

  const visualVariables = {
    sm: VISUAL_VARIABLES_SM,
    md: VISUAL_VARIABLES_MD,
    lg: VISUAL_VARIABLES_LG,
  }

  return new UniqueValueRenderer({
    field: 'markerType',
    uniqueValueInfos: [
      {
        value: `vine_${VineStateIdEnum.Young}`,
        symbol: new SimpleMarkerSymbol({ color: MAP_CLR.YOUNG }),
      },
      {
        value: `vine_${VineStateIdEnum.Missing}`,
        symbol: new SimpleMarkerSymbol({ color: MAP_CLR.MISSING }),
      },
      {
        value: `vine_${VineStateIdEnum.Dead}`,
        symbol: new SimpleMarkerSymbol({ color: MAP_CLR.DEAD }),
      },
      {
        value: 'cane_0',
        symbol: new SimpleMarkerSymbol({ color: MAP_CLR.CANE_0 }),
      },
      {
        value: 'cane_1',
        symbol: new SimpleMarkerSymbol({ color: MAP_CLR.CANE_1 }),
      },
      {
        value: 'cane_2',
        symbol: new SimpleMarkerSymbol({ color: MAP_CLR.CANE_2 }),
      },
      {
        value: 'cane_3',
        symbol: new SimpleMarkerSymbol({ color: MAP_CLR.CANE_3 }),
      },
      {
        value: 'cordon_1',
        symbol: new SimpleMarkerSymbol({ color: MAP_CLR.CORDON_1 }),
      },
      {
        value: `post_${PostMaterialEnum.Wood}`,
        symbol: new SimpleMarkerSymbol({ color: MAP_CLR.WOOD }),
      },
      {
        value: `post_${PostMaterialEnum.Metal}`,
        symbol: new SimpleMarkerSymbol({ color: MAP_CLR.METAL }),
      },
      {
        value: `post_${PostMaterialEnum.Plastic}`,
        symbol: new SimpleMarkerSymbol({ color: MAP_CLR.PLASTIC }),
      },
      {
        value: 'post_missing',
        symbol: new SimpleMarkerSymbol({ color: MAP_CLR.MISSING }),
      },
    ],
    visualVariables: visualVariables[size || _size],
  })
}
