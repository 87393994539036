import { colorBlind, standard } from '@services/theme/palette'
import { NavLinkItem, SettingToggleIconItem } from '@typings/component'

export const healthNavLinks: NavLinkItem[] = [
  {
    name: 'map_&_evidence',
    path: '/map',
    icon: 'material-symbols:map',
  },
  {
    name: 'summary',
    path: '/summary',
    icon: 'dashicons:analytics',
  },
  {
    name: 'reporting',
    path: '/reporting',
    icon: 'material-symbols:assignment-outline',
  },
]

export const yieldNavLinks: NavLinkItem[] = [
  {
    name: 'summary',
    path: '/summary',
    icon: 'dashicons:analytics',
  },
  {
    name: 'reporting',
    path: '/reporting',
    icon: 'material-symbols:assignment-outline',
  },
]

export const pruningNavLinks: NavLinkItem[] = [
  {
    name: 'map_&_evidence',
    path: '/map',
    icon: 'material-symbols:map',
  },
  {
    name: 'summary',
    path: '/summary',
    icon: 'dashicons:analytics',
  },
  {
    name: 'reporting',
    path: '/reporting',
    icon: 'material-symbols:assignment-outline',
  },
]

export const landNavLinks: NavLinkItem[] = [
  {
    name: 'map_&_evidence',
    path: '/map',
    icon: 'material-symbols:map',
  },
  {
    name: 'summary',
    path: '/summary',
    icon: 'dashicons:analytics',
  },
  {
    name: 'reporting',
    path: '/reporting',
    icon: 'material-symbols:assignment-outline',
  },
]

export const diseaseFilterToggles = [
  { symbol: 'MW', label: 'powdery_mildew', value: 'pm' },
  { symbol: 'BT', label: 'botrytis', value: 'bt' },
  { symbol: 'TD', label: 'trunk_disease', value: 'td' },
] as const

export type DiseaseFilterToggleKey = (typeof diseaseFilterToggles)[number]['value']

export const themeOptions: SettingToggleIconItem[] = [
  {
    label: 'Standard',
    value: 'standard',
    colors: standard.range,
  },
  {
    label: 'Color Blind',
    value: 'color-blind',
    colors: colorBlind.range,
  },
]

export const markerSizeOptions: SettingToggleIconItem[] = [
  {
    label: 'Small',
    value: 'sm',
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 24 24"
        style={{ filter: 'drop-shadow(1px 2px 3px rgba(0, 0, 0, 0.3))' }}
      >
        <path
          fill="currentColor"
          d="M12 22q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22"
        ></path>
      </svg>
    ),
  },
  {
    label: 'Medium',
    value: 'md',
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={22}
        height={22}
        viewBox="0 0 24 24"
        style={{ filter: 'drop-shadow(1px 2px 3px rgba(0, 0, 0, 0.3))' }}
      >
        <path
          fill="currentColor"
          d="M12 22q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22"
        ></path>
      </svg>
    ),
  },
  {
    label: 'Large',
    value: 'lg',
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={28}
        height={28}
        viewBox="0 0 24 24"
        style={{ filter: 'drop-shadow(1px 2px 3px rgba(0, 0, 0, 0.3))' }}
      >
        <path
          fill="currentColor"
          d="M12 22q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22"
        ></path>
      </svg>
    ),
  },
]
