import { moveViewToGeometry } from '@/arcgis/controllers'
import { useAddPruningVineAndRowGraphics } from '@/arcgis/hooks/useAddPruningVineAndRowGraphics'
import MapView from '@arcgis/core/views/MapView'
import DateRangeSlider from '@components/form/DateRangeSlider'
import Divider from '@mui/material/Divider'
import Paper from '@mui/material/Paper'
import useVineyardStore from '@stores/vineyard'
import { FilterToggle } from '@typings/common'
import { BlockReportsByBlock } from '@typings/component'
import { PruningStyleUnion } from '@typings/dtos/block'
import { CaneCountEnum, CordonCountEnum, VineStateIdEnum } from '@typings/dtos/block-report/enums'
import { MAP_CLR } from '@utils/constants'
import { findOldestAndNewestDates } from '@utils/utility-fns'
import { t } from 'i18next'
import { Fragment, useEffect, useState } from 'react'
import { useShallow } from 'zustand/react/shallow'
import MapFilterToggleButton from '../MapFilterToggleButton'
import TemporalSlidersWidget from '../TemporalSlidersWidget'
import PruningCustomMarksRangeSlider from './PruningCustomMarksRangeSlider'

type PruningSlidersGroupProps = {
  mapView: MapView | null
  data: Record<PruningStyleUnion, BlockReportsByBlock[]>
  isFetching: boolean
}

const filterValues = [`vine_${VineStateIdEnum.Young}`, `cane_${CaneCountEnum.Cane1}`]

const PruningSlidersGroup = ({ mapView, data, isFetching }: PruningSlidersGroupProps) => {
  const [reports, setReports] = useState<BlockReportsByBlock[]>()
  const [activeBlockId, setActiveBlockId] = useState<number | null>(null)
  const [showGraphics, setShowGraphics] = useState<string[]>(filterValues)

  const [startDate, endDate, selectedBlocks, activePruningStyle] = useVineyardStore(
    useShallow((s) => [s.startDate, s.endDate, s.selectedBlocks, s.activePruningStyle])
  )
  const [targetDate, setTargetDate] = useState<number>(new Date(endDate).getTime())

  useEffect(() => {
    if (activeBlockId) {
      const block = selectedBlocks.find((block) => block.id === activeBlockId)
      if (block && mapView) {
        const { coordinates } = block.geometry as unknown as { coordinates: __esri.Geometry[] }
        moveViewToGeometry(coordinates, mapView, { duration: 500, easing: 'ease' })
      }
    }
  }, [activeBlockId, mapView, selectedBlocks])

  const updateShowGraphics = (key: string) => {
    if (showGraphics.includes(key)) setShowGraphics(showGraphics.filter((detection) => detection !== key))
    else setShowGraphics([...showGraphics, key])
  }

  useEffect(() => {
    setReports(data[activePruningStyle])
  }, [data, activePruningStyle])

  const { oldestDate, newestDate } = findOldestAndNewestDates(reports)

  useAddPruningVineAndRowGraphics({ reports, targetDate, showGraphics })

  const vineFilters: FilterToggle[] = [
    {
      symbol: <div style={{ height: 15, width: 15, backgroundColor: MAP_CLR.YOUNG, borderRadius: 15 }} />,
      label: t('young'),
      value: `vine_${VineStateIdEnum.Young}`,
    },
    {
      symbol: <div style={{ height: 15, width: 15, backgroundColor: MAP_CLR.MISSING, borderRadius: 15 }} />,
      label: t('missing'),
      value: `vine_${VineStateIdEnum.Missing}`,
    },
  ]

  const caneFilters: FilterToggle[] = [
    {
      symbol: <div style={{ height: 15, width: 15, backgroundColor: MAP_CLR.CANE_0, borderRadius: 15 }} />,
      label: `${t('dead')} / 0 ${t('cane')}`,
      value: `vine_${VineStateIdEnum.Dead}`,
    },
    {
      symbol: <div style={{ height: 15, width: 15, backgroundColor: MAP_CLR.CANE_1, borderRadius: 15 }} />,
      label: `1 ${t('cane')}`,
      value: `cane_${CaneCountEnum.Cane1}`,
    },
    {
      symbol: <div style={{ height: 15, width: 15, backgroundColor: MAP_CLR.CANE_2, borderRadius: 15 }} />,
      label: `2 ${t('cane')}`,
      value: `cane_${CaneCountEnum.Cane2}`,
    },
    {
      symbol: <div style={{ height: 15, width: 15, backgroundColor: MAP_CLR.CANE_3, borderRadius: 15 }} />,
      label: `3 ${t('cane')}`,
      value: `cane_${CaneCountEnum.Cane3}`,
    },
  ]

  const cordonFilters: FilterToggle[] = [
    {
      symbol: <div style={{ height: 15, width: 15, backgroundColor: MAP_CLR.CORDON_0, borderRadius: 15 }} />,
      label: `${t('dead')} / 0 ${t('cordon')}`,
      value: `vine_${VineStateIdEnum.Dead}`,
    },
    {
      symbol: <div style={{ height: 15, width: 15, backgroundColor: MAP_CLR.CORDON_1, borderRadius: 15 }} />,
      label: `1 ${t('cordon')}`,
      value: `cordon_${CordonCountEnum.Cordon1}`,
    },
  ]

  const filtersByPruningStyle: Record<PruningStyleUnion, FilterToggle[]> = {
    cane: [...caneFilters, ...vineFilters],
    spur: [...cordonFilters, ...vineFilters],
    bow: vineFilters,
  }

  return (
    <TemporalSlidersWidget
      oldestDate={oldestDate}
      newestDate={newestDate}
      isFetchingData={isFetching}
      temporalSlidersHeader={
        <Paper sx={{ borderRadius: 0, display: 'flex', width: 'max-content', ml: 'auto', alignItems: 'start' }}>
          {filtersByPruningStyle[activePruningStyle].map(({ symbol, label, value }, i) => (
            <Fragment key={label}>
              <MapFilterToggleButton
                isActive={showGraphics.includes(value)}
                symbol={symbol}
                label={label}
                onClick={() => updateShowGraphics(value)}
              />
              {filtersByPruningStyle[activePruningStyle].length > 1 &&
              i <= filtersByPruningStyle[activePruningStyle].length - 2 ? (
                <Divider orientation="vertical" />
              ) : null}
            </Fragment>
          ))}
        </Paper>
      }
      temporalRangeSliders={
        <div style={{ display: 'grid', maxHeight: 'calc(100dvh - 28rem)', overflowY: 'auto', overflowX: 'hidden' }}>
          {reports &&
            reports.map((r, i) => {
              return (
                <div
                  key={r.blockId}
                  onClick={() => setActiveBlockId(r.blockId)}
                  style={{
                    backgroundColor: r.blockId === activeBlockId ? '#F5F5F4' : '',
                    borderTop: i === 0 ? '' : '1px solid #E0E0E0',
                    borderBottom: '1px solid #E0E0E0',
                    padding: '16px 8px 8px 8px',
                  }}
                >
                  <PruningCustomMarksRangeSlider
                    targetDate={targetDate}
                    blockName={r.blockName}
                    reports={r.reports}
                    customOnChange={(item) => setTargetDate(item.value)}
                    sDate={oldestDate}
                    eDate={newestDate}
                  />
                </div>
              )
            })}
        </div>
      }
      parentRangeSlider={
        <DateRangeSlider
          startDate={startDate}
          endDate={endDate}
          targetDate={targetDate}
          customOnChange={(item) => setTargetDate(Number(item.date))}
        />
      }
    />
  )
}

export default PruningSlidersGroup
