import { vinesLayer } from '@/arcgis/layers'
import { VineAndPostLayer } from '@/arcgis/layers/pruning-vines.layer'
import { vinesAndPostsRenderer, vinesLayerRenderer } from '@/arcgis/utils'
import DrawerHead from '@components/drawer/DrawerHead'
import ToggleButtonGroup from '@components/form/ToggleIconButtonGroup'
import { useHandleAxiosError } from '@hooks/useHandleAxiosError'
import { useTranslate } from '@hooks/useLocales'
import Drawer from '@mui/material/Drawer'
import Typography from '@mui/material/Typography'
import { UserApi } from '@services/api'
import { allLangs } from '@services/locales/config-lang'
import useAuthStore from '@stores/auth'
import { useMutation } from '@tanstack/react-query'
import { ThemeMode } from '@typings/common'
import { markerSizeOptions, themeOptions } from '@utils/component-utils'
import { useEffect, useState } from 'react'

type SettingsDrawerProps = {
  open: boolean
  onClose: () => void
}

const SettingsDrawer = ({ open, onClose }: SettingsDrawerProps) => {
  const [markerSize, setMarkerSize] = useState('md')
  const { t, onChangeLang } = useTranslate()
  const [userSettings, setUserSettings] = useAuthStore((s) => [s.userSettings, s.setUserSettings])
  const handleAxiosError = useHandleAxiosError()

  const { mutate } = useMutation({
    mutationKey: ['userSettings'],
    mutationFn: UserApi.postUserSettings,
    onSuccess: (data) => {
      setUserSettings(data)
      onChangeLang(data.language)
    },
    onError: (error) => handleAxiosError(error),
  })

  const updateMarkerSize = async (size: 'sm' | 'md' | 'lg') => {
    localStorage.setItem('map_marker_size', size)
    setMarkerSize(size)
    vinesLayer.renderer = vinesLayerRenderer(size)
    VineAndPostLayer.renderer = vinesAndPostsRenderer(size)
  }

  useEffect(() => {
    const size = localStorage.getItem('map_marker_size') as 'sm' | 'md' | 'lg'
    setMarkerSize(size || 'md')
  }, [])

  if (!userSettings) return null

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      slotProps={{ backdrop: { invisible: true } }}
      PaperProps={{ sx: { width: 340, overflow: 'hidden' } }}
    >
      <DrawerHead label={t('customize')} onClose={onClose} icon="teenyicons:toggle-solid" />
      <div style={{ padding: '0px 1.2rem', display: 'grid', gap: '2rem', marginTop: '1rem' }}>
        <div>
          <Typography fontWeight={500} fontSize={14} color="grey.700" mb={1}>
            {t('colour')}
          </Typography>
          <ToggleButtonGroup
            options={themeOptions}
            selectedValue={userSettings.theme || 'standard'}
            onClick={(value) => mutate({ ...userSettings, theme: value as ThemeMode })}
          />
        </div>
        <div>
          <Typography fontWeight={500} fontSize={14} color="grey.700" mb={1}>
            {t('language')}
          </Typography>
          <ToggleButtonGroup
            options={allLangs}
            selectedValue={userSettings.language || 'gb'}
            onClick={(value) => mutate({ ...userSettings, language: value })}
          />
        </div>
        <div>
          <Typography fontWeight={500} fontSize={14} color="grey.700" mb={1}>
            {t('map_marker_size')}
          </Typography>
          <ToggleButtonGroup
            options={markerSizeOptions}
            selectedValue={markerSize}
            onClick={(v) => updateMarkerSize(v as 'sm' | 'md' | 'lg')}
          />
        </div>
      </div>
    </Drawer>
  )
}

export default SettingsDrawer
