import SpatialReference from '@arcgis/core/geometry/SpatialReference'
import FeatureLayer from '@arcgis/core/layers/FeatureLayer'
import { vinesLayerRenderer } from '../utils'

export const vinesLayer = new FeatureLayer({
  id: 'vines-layer',
  title: 'Vines Layer',
  source: [],
  fields: [
    { name: 'id', type: 'integer' },
    { name: 'renderType', type: 'string' },
    { name: 'haveImages', type: 'string' },
    { name: 'vine', type: 'string' },
  ],
  objectIdField: 'objectId',
  geometryType: 'point',
  outFields: ['id', 'vine'],
  spatialReference: SpatialReference.WGS84,
  renderer: vinesLayerRenderer(),
  popupEnabled: true,
  popupTemplate: {},
})
