export const VISUAL_VARIABLES_SM = [
  {
    type: 'size',
    valueExpression: '$view.scale',
    stops: [
      { size: 40, value: 40 },
      { size: 24, value: 100 },
      { size: 14, value: 200 },
      { size: 8, value: 400 },
      { size: 6, value: 800 },
      { size: 4.5, value: 1500 },
      { size: 3.5, value: 5000 },
      { size: 2.5, value: 10000 },
    ],
  } as __esri.SizeVariableProperties,
]

export const VISUAL_VARIABLES_MD = [
  {
    type: 'size',
    valueExpression: '$view.scale',
    stops: [
      { size: 45, value: 40 },
      { size: 28, value: 100 },
      { size: 16, value: 200 },
      { size: 10, value: 400 },
      { size: 8, value: 800 },
      { size: 6, value: 1500 },
      { size: 4, value: 5000 },
      { size: 3, value: 10000 },
    ],
  } as __esri.SizeVariableProperties,
]

export const VISUAL_VARIABLES_LG = [
  {
    type: 'size',
    valueExpression: '$view.scale',
    stops: [
      { size: 62, value: 40 },
      { size: 38, value: 100 },
      { size: 20, value: 200 },
      { size: 13, value: 400 },
      { size: 11, value: 800 },
      { size: 9, value: 1500 },
      { size: 6, value: 5000 },
      { size: 4, value: 10000 },
    ],
  } as __esri.SizeVariableProperties,
]
