import SpatialReference from '@arcgis/core/geometry/SpatialReference'
import FeatureLayer from '@arcgis/core/layers/FeatureLayer'

// Delay importing vinesAndPostsRenderer
import('../utils').then(({ vinesAndPostsRenderer }) => {
  VineAndPostLayer.renderer = vinesAndPostsRenderer()
  VineAndPostLayer.refresh() // Force a refresh after setting renderer
})

export const VineAndPostLayer = new FeatureLayer({
  id: 'vines-post-layer',
  title: 'Vines and Posts Layer',
  source: [],
  fields: [
    { name: 'id', type: 'integer' },
    { name: 'markerType', type: 'string' },
    { name: 'details', type: 'string' },
  ],
  objectIdField: 'objectId',
  geometryType: 'point',
  outFields: ['id', 'markerType', 'details'],
  spatialReference: SpatialReference.WGS84,
  popupEnabled: true,
  popupTemplate: {},
})
